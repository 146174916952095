import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

function ThreeColumnIcons(props) {
  const { data } = props;
  return (
    <Container maxWidth="lg">
      <Box
        component={Grid}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        {data.map((item, key) => {
          return (
            <Box component={Grid} item xs={12} md={2} key={key}>
              <Box mb={4} display="flex" justifyContent="center">
                <img
                  src={item.image.url}
                  alt={item.image.alt}
                  style={{ height: 'auto', width: 'auto' }}
                />
              </Box>
              <Box
                display={{ xs: 'flex', md: 'none' }}
                justifyContent="center"
                mb={{ xs: 4, md: 0 }}
              >
                <Box
                  component="div"
                  display="flex"
                  justifyContent="center"
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: item.title.html,
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        component={Grid}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        {data.map((item, key) => {
          return (
            <Grid item xs={12} md={2} key={key}>
              <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center">
                <Box
                  component="div"
                  display="flex"
                  justifyContent="center"
                  className="text-center"
                  dangerouslySetInnerHTML={{
                    __html: item.title.html,
                  }}
                />
              </Box>
            </Grid>
          );
        })}
      </Box>
    </Container>
  );
}

ThreeColumnIcons.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ThreeColumnIcons;
