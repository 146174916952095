import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function CenteredTitle(props) {
  const { data } = props;
  return (
    <Container maxWidth="xl">
      <Box
        component="div"
        display="flex"
        alignItems="center"
        justifyContent="center"
        dangerouslySetInnerHTML={{
          __html: data.title.html,
        }}
      />
    </Container>
  );
}

CenteredTitle.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CenteredTitle;
