import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RichText } from 'prismic-reactjs';
import 'lazysizes';

function GridTypeOne(props) {
  const { data } = props;
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box component={Grid} container justifyContent="space-between">
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 8, md: 20 }} pr={{ xs: 0, lg: 20 }}>
              <Box component="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.content.html,
                  }}
                />
              </Box>
              <Box display={{ xs: 'none', lg: 'block' }} width="50%" mt={10}>
                {data.square_tile_small.url && (
                  <img
                    data-srcset={data.square_tile_small.fluid.srcSet}
                    className="lazyload blur-up"
                    alt={data.square_tile_small.alt}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Box
            component={Grid}
            item
            xs={12}
            lg={6}
            display="flex"
            justifyContent={{ xs: 'flex-start', lg: 'center' }}
          >
            <Box
              display={{ xs: 'block', lg: 'none' }}
              width="50%"
              pr={{ xs: 2, sm: 3, md: 4 }}
            >
              {data.square_tile_small.url && (
                <img
                  data-srcset={data.square_tile_small.fluid.srcSet}
                  className="lazyload blur-up"
                  alt={data.square_tile_small.alt}
                />
              )}
            </Box>
            <Box width="100%">
              {data.portrait_image.url && (
                <img
                  data-srcset={data.portrait_image.fluid.srcSet}
                  className="lazyload blur-up"
                  alt={data.portrait_image.alt}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box component={Grid} container pt={16}>
          <Grid item xs={10} lg={6}>
            {data.square_tile.url && (
              <img
                data-srcset={data.square_tile.fluid.srcSet}
                className="lazyload blur-up"
                alt={data.square_tile.alt}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box pl={{ xs: 0, lg: 20 }} pt={{ xs: 16, lg: 0 }}>
              <Box
                component="div"
                dangerouslySetInnerHTML={{
                  __html: data.square_tile_title.html,
                }}
              />
            </Box>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Box component={Grid} container pt={16}>
          <Grid item xs={12}>
            {data.full_width_image.url && (
              <img
                data-srcset={data.full_width_image.fluid.srcSet}
                className="lazyload blur-up"
                alt={data.full_width_image.alt}
              />
            )}
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="lg">
        <Box component={Grid} container pt={16} justifyContent="space-between">
          <Grid item xs={12} lg={5}>
            <Box component="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.grid_footer_content.html,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            {data.grid_footer_image_square.url && (
              <img
                data-srcset={data.grid_footer_image_square.fluid.srcSet}
                className="lazyload blur-up"
                alt={data.grid_footer_image_square.alt}
              />
            )}
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Box component={Grid} container pt={16}>
          <Grid item xs={12}>
            {data.grid_footer_full_width_image.url && (
              <img
                data-srcset={data.grid_footer_full_width_image.fluid.srcSet}
                className="lazyload blur-up"
                alt={data.grid_footer_full_width_image.alt}
              />
            )}
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}

GridTypeOne.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GridTypeOne;
