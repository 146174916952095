import React from 'react';
import PropTypes from 'prop-types';
import linkResolver from 'helpers/linkResolver';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'gatsby-link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

function StandardButton(props) {
  const { data, btntext } = props;
  return (
    <Box className="standard-button" onMouseEnter={e => console.log(e)}>
      <Box
        component={Link}
        to={linkResolver(data.link)}
        display="flex"
        alignItems="center"
      >
        <Typography component="span" variant="button" color="textSecondary">
          {data.link_text ? data.link_text.text : btntext}
        </Typography>
        <Box pl={15}>
          <Typography component="span" color="textSecondary">
            <ArrowForwardIcon />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

StandardButton.propTypes = {
  data: PropTypes.object.isRequired,
  btntext: PropTypes.string,
};

export default StandardButton;
