import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const HeroWrapper = styled.div`
  width: 100vw;
  height: auto;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
`;

function HeroDefault(props) {
  const { data } = props;
  return (
    <Box mb={0}>
      <HeroWrapper>
        {data.data.banner_image && data.data.banner_image.url && (
          <img
            data-srcset={data.data.banner_image.fluid.srcSet}
            className="lazyload blur-up"
            alt={data.data.banner_image.alt}
          />
        )}
      </HeroWrapper>
    </Box>
  );
}

HeroDefault.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HeroDefault;
