import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SvgEstateMap from 'components/ui/SvgEstateMap';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'transparent',
    width: '100%',
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: 0,
  },
  accordionItem: {
    background: 'transparent',
    boxShadow: 'none',
  },
  AccordionSummary: {
    padding: 0,
    flexDirection: 'row-reverse',
  },
}));

const CopyPostcode = value => {
  return (
    <CopyToClipboard text={value.value}>
      <Typography
        component="button"
        variant="body2"
        className="postcode-button"
        color="textPrimary"
      >
        {value.value}
      </Typography>
    </CopyToClipboard>
  );
};

function EstateMap() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('access');
  const [dot, setDot] = useState('');
  const [dotselected, setDotselected] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '');
    setDot('');
    setDotselected(false);
  };

  const handleDotSelection = dot => e => {
    setDot(dot);
    setDotselected(true);
  };

  return (
    <Box bgcolor="primary.main" py={20}>
      <Container maxWidth="xl">
        <Typography variant="h3" color="textSecondary">
          ESTATE MAP
        </Typography>
        <Box
          component={Grid}
          display="flex"
          justifyContent="space-between"
          container
          pt={10}
        >
          <Grid item xs={12} lg={7}>
            <SvgEstateMap
              expanded={expanded}
              dot={dot}
              dotselected={dotselected}
            />
          </Grid>
          <Box component={Grid} item xs={12} lg={4} pt={{ xs: 8, lg: 0 }}>
            <div className={classes.root}>
              <Accordion
                expanded={expanded === 'access'}
                onChange={handleChange('access')}
                className={classes.accordionItem}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className={classes.AccordionSummary}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className={classes.heading}
                  >
                    ACCESS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-1'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle purple" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-1')}
                        >
                          Main pedestrian gate
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2UB" />
                      </Box>
                    </Box>
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-2'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle green" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-2')}
                        >
                          Arlington gate
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2UB" />
                      </Box>
                    </Box>

                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-3'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle blue" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-3')}
                        >
                          Staten gate
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2UA" />
                      </Box>
                    </Box>

                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-4'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle brownred" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-4')}
                        >
                          Manhattan gate
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2TY" />
                      </Box>
                    </Box>

                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-5'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle lgreen" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-5')}
                        >
                          Park East gate
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2UT" />
                      </Box>
                    </Box>

                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-6'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle pink" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-6')}
                        >
                          Park East pedestrian gate
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2UT" />
                      </Box>
                    </Box>

                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-7'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle yellow" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-7')}
                        >
                          Shutters to upper and lower car park
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2TY" />
                      </Box>
                    </Box>

                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-8'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle lred" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-8')}
                        >
                          Shutters to basement car park
                        </Typography>
                      </Box>
                      <Box>
                        <CopyPostcode value="E3 2TY" />
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'services'}
                onChange={handleChange('services')}
                className={classes.accordionItem}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  className={classes.AccordionSummary}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className={classes.heading}
                  >
                    SERVICES
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-9'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle purple" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-9')}
                        >
                          Estate Office
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" width="100%">
                      <Box
                        p={2}
                        className={`list-box ${
                          dotselected === true
                            ? dot == 'dot-10'
                              ? 'active'
                              : 'faded'
                            : ''
                        }`}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={2}
                      >
                        <Box display="flex">
                          <Box className="circle green" mr={2} />
                          <Typography
                            className="text-uppercase btn-plain-text"
                            color="textSecondary"
                            variant="subtitle1"
                            component="button"
                            align="left"
                            onClick={handleDotSelection('dot-10')}
                          >
                            Concierge Lodge
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'amenities'}
                onChange={handleChange('amenities')}
                className={classes.accordionItem}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                  className={classes.AccordionSummary}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className={classes.heading}
                  >
                    AMENITIES
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-11'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle purple" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-11')}
                        >
                          Shop
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-12'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle green" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-12')}
                        >
                          Estate Agent
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 'facilities'}
                onChange={handleChange('facilities')}
                className={classes.accordionItem}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                  className={classes.AccordionSummary}
                >
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    className={classes.heading}
                  >
                    FACILITIES
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-13'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle purple" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-13')}
                        >
                          BICYCLE RACKS
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-14'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle green" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-14')}
                        >
                          Bin Rooms
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      p={2}
                      className={`list-box ${
                        dotselected === true
                          ? dot == 'dot-15'
                            ? 'active'
                            : 'faded'
                          : ''
                      }`}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <Box display="flex">
                        <Box className="circle blue" mr={2} />
                        <Typography
                          className="text-uppercase btn-plain-text"
                          color="textSecondary"
                          variant="subtitle1"
                          component="button"
                          align="left"
                          onClick={handleDotSelection('dot-15')}
                        >
                          Manhattan & Lexington service lifts
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default EstateMap;
