import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import 'lazysizes';

function ThreeQuarterImage(props) {
  const { data } = props;
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} sm={10} md={8}>
          <Box pl={{ xs: 0, sm: 5, md: 10 }}>
            <img
              data-srcset={data.image.fluid.srcSet}
              className="lazyload blur-up"
              alt={data.image.alt}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

ThreeQuarterImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ThreeQuarterImage;
