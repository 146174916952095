import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { StandardButton } from 'components/ui';

function StandaloneButton(props) {
  const { data } = props;
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} sm={10} md={8}>
          <Box pl={{ xs: 0, sm: 5, md: 10 }} mt={12}>
            <StandardButton data={data} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

StandaloneButton.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StandaloneButton;
