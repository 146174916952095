import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import 'lazysizes';

function TextLeftImageRight(props) {
  const { data, items } = props;
  console.log(items);
  return (
    <Container maxWidth="xl">
      <div className="spaced-grid-wrapper">
        <Box component={Grid} container justifyContent="space-between">
          <Box component={Grid} item xs={12} lg={4}>
            <Box mb={{ xs: 8, lg: 0 }}>
              <Box component="div">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.text.html,
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Grid item xs={12} lg={7}>
            <Box pb={4}>
              <img
                data-srcset={data.image.fluid.srcSet}
                className="lazyload blur-up"
                alt={data.image.alt}
              />
            </Box>
            {items && (
              <React.Fragment>
                {items.map((item, key) => {
                  if (item.repeatable_image.url) {
                    if (key % 2 == 0) {
                      return (
                        <Box
                          key={key}
                          py={4}
                          pl={{ xs: 10, sm: 12, md: 20, lg: 0 }}
                        >
                          <img
                            data-srcset={item.repeatable_image.fluid.srcSet}
                            className="lazyload blur-up"
                            alt={item.repeatable_image.alt}
                          />
                        </Box>
                      );
                    } else {
                      return (
                        <Box
                          key={key}
                          py={4}
                          pr={{ xs: 10, sm: 12, md: 20, lg: 0 }}
                        >
                          <img
                            data-srcset={item.repeatable_image.fluid.srcSet}
                            className="lazyload blur-up"
                            alt={item.repeatable_image.alt}
                          />
                        </Box>
                      );
                    }
                  }
                })}
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </div>
    </Container>
  );
}

TextLeftImageRight.propTypes = {
  data: PropTypes.object.isRequired,
  items: PropTypes.array,
};

export default TextLeftImageRight;
