import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import 'lazysizes';

function ImageGridStyleTwo(props) {
  const { data } = props;
  return (
    <Container maxWidth="xl">
      <Box
        component={Grid}
        container
        display="flex"
        justifyContent="space-around"
      >
        <Grid item xs={6} lg={5}>
          <img
            src={`${data.image_one.url}&ar=0.65:1&fit=crop`}
            className="lazyload blur-up"
            alt={data.image_one.alt}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pl={{ xs: 8, lg: 0 }}>
            <img
              src={`${data.image_two.url}&ar=0.65:1&fit=crop`}
              className="lazyload blur-up"
              alt={data.image_two.alt}
            />
          </Box>
        </Grid>
        <Box component={Grid} item xs={12} py={16}>
          <img
            data-srcset={data.image_three.fluid.srcSet}
            className="lazyload blur-up"
            alt={data.image_three.alt}
          />
        </Box>
      </Box>
      <Box
        component={Grid}
        container
        display="flex"
        justifyContent={{ xs: 'flex-start', lg: 'space-around' }}
      >
        <Box
          component={Grid}
          item
          xs={5}
          pb={{ xs: 8, lg: 0 }}
          pr={{ xs: 10, sm: 20, md: 40, lg: 50 }}
        >
          <img
            src={`${data.image_four.url}&ar=1:1&fit=crop`}
            className="lazyload blur-up"
            alt={data.image_four.alt}
          />
        </Box>
        <Grid item xs={12} lg={4}>
          <div
            dangerouslySetInnerHTML={{
              __html: data.content.html,
            }}
          />
        </Grid>
      </Box>
      <Box
        component={Grid}
        container
        display="flex"
        justifyContent="space-around"
        pt={16}
      >
        <Grid item xs={6} lg={5}>
          <img
            src={`${data.image_five.url}&ar=0.65:1&fit=crop`}
            className="lazyload blur-up"
            alt={data.image_five.alt}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pl={{ xs: 8, lg: 0 }}>
            <img
              src={`${data.image_six.url}&ar=1:1&fit=crop`}
              className="lazyload blur-up"
              alt={data.image_six.alt}
            />
          </Box>
        </Grid>
      </Box>
    </Container>
  );
}

ImageGridStyleTwo.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImageGridStyleTwo;
