import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function Spacer(props) {
  const { children, spacerTop, spacerBottom } = props;
  var paddingTop = spacerTop ? spacerTop : 19;
  var paddingBottom = spacerBottom ? spacerBottom : 0;
  if (spacerTop != null && spacerTop == 0) {
    paddingTop = 0;
  }
  if (spacerBottom != null && spacerBottom == 0) {
    paddingBottom = 0;
  }
  return (
    <Box pt={paddingTop} pb={paddingBottom} className="bg-white spacer-wrapper">
      {children}
    </Box>
  );
}

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
  spacerTop: PropTypes.number,
  spacerBottom: PropTypes.number,
};

export default Spacer;
