import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { StandardButton } from 'components/ui';
import {
  motion,
  useViewportScroll,
  useTransform,
  useMotionValue,
} from 'framer-motion';

export const Background = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

function ParallaxSections(props) {
  const { data } = props;
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [2.5, 1]);

  return (
    <div className="parallax-wrapper">
      {data.map((item, key) => {
        return (
          <div className={`parallax-item parallax-item-${key}`} key={key}>
            <div className="project-200vh">
              <div className="project-image-mask">
                <motion.img
                  src={item.image.url}
                  alt=""
                  className="project-image"
                  style={{
                    scale,
                  }}
                />
                <motion.div className="project-image-overlay" />
              </div>
              <div className="project-info bg-blue">
                <Container maxWidth="xl">
                  <Box className="bg-blue" width="100%" py={10}>
                    <Grid container>
                      <Grid item xs={12} lg={4}>
                        <Typography
                          variant="h4"
                          color="textSecondary"
                          className="text-uppercase"
                        >
                          {item.title.text}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Box mt={{ xs: 2, lg: 0 }}>
                          <Typography
                            color="secondary"
                            variant="body2"
                            component="p"
                          >
                            {item.content.text}
                          </Typography>
                        </Box>
                      </Grid>
                      <Box
                        component={Grid}
                        item
                        xs={12}
                        lg={4}
                        display="flex"
                        justifyContent={{ xs: 'flex-start', lg: 'center' }}
                      >
                        <div>
                          <StandardButton data={item} btntext="FIND OUT MORE" />
                        </div>
                      </Box>
                    </Grid>
                  </Box>
                </Container>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

ParallaxSections.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ParallaxSections;
