import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';

function StandaloneImage(props) {
  const { data } = props;
  return (
    <Container maxWidth="xl">
      <img src={data.image.url} alt={data.image.alt} />
    </Container>
  );
}

StandaloneImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StandaloneImage;
